import { useCallback, useEffect, useState } from "react";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FaLaptopCode, FaSquareXTwitter } from "react-icons/fa6";
import { GoCodeSquare } from "react-icons/go";
import { IoLogoWhatsapp } from "react-icons/io";
import { PiBrainDuotone } from "react-icons/pi";
import { RiBrainFill } from "react-icons/ri";
import Divider from "src/components/Divider/Divider";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Progress } from "src/components/ui/progress";
import {
  useCbtDetailsQuery,
  useResultQuery,
} from "src/lib/endpoints/question/question.api";

type Extras = {
  name?: string;
  percentage?: number;
};

const Results = () => {
  const { result } = useResultQuery();
  const { cbtDetails } = useCbtDetailsQuery();
  const [frameworks, setFrameworks] = useState<Extras[]>([]);
  const [skills, setSkills] = useState<Extras[]>([]);
  const [languages, setLanguages] = useState<Extras[]>([]);

  const filterDetails = useCallback(() => {
    if (!result) return;

    let idx = 1;

    while (
      `language${idx}_name` in result ||
      `skill${idx}_name` in result ||
      `framework${idx}_name` in result
    ) {
      if (`language${idx}_name` in result) {
        const nameField = `language${idx}_name`;
        const scoreField = `language${idx}_percent_score`;
        if (result[nameField] !== null) {
          const data: Extras = {
            name: result[nameField],
            percentage: result[scoreField],
          };
          setLanguages((prev) => prev.concat([data]));
        }
      }

      if (`framework${idx}_name` in result) {
        const nameField = `framework${idx}_name`;
        const scoreField = `framework${idx}_percent_score`;
        if (result[nameField] !== null) {
          const data: Extras = {
            name: result[nameField],
            percentage: result[scoreField],
          };
          setFrameworks((prev) => prev.concat([data]));
        }
      }

      if (`skill${idx}_name` in result) {
        const nameField = `skill${idx}_name`;
        const scoreField = `skill${idx}_percent_score`;
        if (result[nameField] !== null) {
          const data: Extras = {
            name: result[nameField],
            percentage: result[scoreField],
          };
          setSkills((prev) => prev.concat([data]));
        }
      }
      idx++;
    }
  }, [result]);

  const refUrl = cbtDetails?.refLink;

  const message = `Proud of my result in Droomwork. You think you can beat me or want to hire me? Join now with this link: ${refUrl}\n\nLanguages:${languages
    .map((language) => `\n${language?.name} = ${language?.percentage}%`)
    .join("")}\n\nFrameworks:${frameworks
    .map((framework) => `\n${framework?.name} = ${framework?.percentage}%`)
    .join("")}\n\nSkills:${skills
    .map((skill) => `\n${skill?.name} = ${skill?.percentage}%`)
    .join("")}\n\nOthers:\nInternet = ${
    result?.internet_score
  }%\nVersion Control = ${result?.version_control_score}%\nSecurity = ${
    result?.security_score
  }%\n\nTotal Score = ${result?.total_score}%\n\n`;

  const tags = "#droomwork #talent #client";

  const shareOnTwitter = () => {
    const hashtags = "droomwork,talents,client";
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}&hashtags=${hashtags}`;
    // &url=${encodeURIComponent(refUrl)}
    window.open(twitterUrl, "_blank");
  };

  const shareOnWhatsapp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message + tags
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnLinkedIn = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(
      message + tags
    )}&url=${encodeURIComponent(refUrl)}`;
    window.open(linkedinShareUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      refUrl
    )}`;
    window.open(facebookShareUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    setLanguages([]);
    setFrameworks([]);
    setSkills([]);
    filterDetails();
  }, [result]);

  return (
    <div className="lg:max-w-[968px] lg:w-[768px] h-screen py-8 w-full flex flex-col justify-center items-center m-auto">
      <div className="w-full">
        <div className="w-full px-5 flex justify-between items-center">
          <h2 className="text-xl lg:text-[1.75rem] font-bold">
            CBT Test Result
          </h2>
          <a
            href={process.env.REACT_APP_TALENT_WEB_URL}
            className="text-blue-600 underline text-sm hover:cursor-pointer"
          >
            Go to dashboard
          </a>
        </div>
        <Divider className={"mb-10 mt-3"} />
      </div>
      {result && (
        <div className="flex flex-col space-y-10 w-full px-8 md:px-10 h-[60vh] overflow-y-auto">
          {/* Language */}
          <section className="flex flex-col space-y-5">
            <div className="flex space-x-3 items-center">
              <GoCodeSquare className="w-7 h-7" />
              <p className="text-lg font-bold">Language</p>
            </div>
            <div className="flex flex-col space-y-3">
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-semibold">Name</p>
                <p className="col-span-1 font-semibold">Result</p>
              </div>
              {languages.map((language, idx) => (
                <div key={idx} className="grid grid-cols-2">
                  <p className="col-span-1 font-medium">{language.name}</p>
                  <div className="col-span-1 font-medium flex space-x-3 items-center">
                    <Progress
                      value={language.percentage}
                      className="w-[40%] border-2 border-gray-500 h-2"
                    />
                    <p>{language.percentage}%</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {/* Framework */}
          <section className="flex flex-col space-y-5">
            <div className="flex space-x-3 items-center">
              <FaLaptopCode className="w-7 h-7" />
              <p className="text-lg font-bold">Framework</p>
            </div>
            <div className="flex flex-col space-y-3">
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-semibold">Name</p>
                <p className="col-span-1 font-semibold">Result</p>
              </div>
              {frameworks.map((framework, idx) => (
                <div key={idx} className="grid grid-cols-2">
                  <p className="col-span-1 font-medium">{framework.name}</p>
                  <div className="col-span-1 font-medium flex space-x-3 items-center">
                    <Progress
                      value={framework.percentage}
                      className="w-[40%] border-2 border-gray-500 h-2"
                    />
                    <p>{framework.percentage}%</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {/* Skill */}
          <section className="flex flex-col space-y-5">
            <div className="flex space-x-3 items-center">
              <PiBrainDuotone className="w-7 h-7" />
              <p className="text-lg font-bold">Skill</p>
            </div>
            <div className="flex flex-col space-y-3">
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-semibold">Name</p>
                <p className="col-span-1 font-semibold">Result</p>
              </div>
              {skills.map((skill, idx) => (
                <div key={idx} className="grid grid-cols-2">
                  <p className="col-span-1 font-medium">{skill.name}</p>
                  <div className="col-span-1 font-medium flex space-x-3 items-center">
                    <Progress
                      value={skill.percentage}
                      className="w-[40%] border-2 border-gray-500 h-2"
                    />
                    <p>{skill.percentage}%</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {/* Others => security, version control, internet */}
          <section className="flex flex-col space-y-5">
            <div className="flex space-x-3 items-center">
              <RiBrainFill className="w-7 h-7" />
              <p className="text-lg font-bold">Others</p>
            </div>
            <div className="flex flex-col space-y-3">
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-semibold">Name</p>
                <p className="col-span-1 font-semibold">Result</p>
              </div>
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-medium">Internet</p>
                <div className="col-span-1 font-medium flex space-x-3 items-center">
                  <Progress
                    value={result.internet_score || 0}
                    className="w-[40%] border-2 border-gray-500 h-2"
                  />
                  <p>{result.internet_score}%</p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-medium">Version Control</p>
                <div className="col-span-1 font-medium flex space-x-3 items-center">
                  <Progress
                    value={result.version_control_score || 0}
                    className="w-[40%] border-2 border-gray-500 h-2"
                  />
                  <p>{result.version_control_score}%</p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <p className="col-span-1 font-medium">Security</p>
                <div className="col-span-1 font-medium flex space-x-3 items-center">
                  <Progress
                    value={result.security_score || 0}
                    className="w-[40%] border-2 border-gray-500 h-2"
                  />
                  <p>{result.security_score}%</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Divider className={"mt-7 mb-8"} />
      <div className="flex justify-around md:justify-between items-center w-full mx-auto md:px-10">
        <Popover>
          <PopoverTrigger asChild>
            <button className={"text-white bg-primary rounded-md px-5 py-3"}>
              Share
            </button>
          </PopoverTrigger>
          <PopoverContent className="p-5 flex flex-row space-x-4 w-fit bg-white">
            <IoLogoWhatsapp
              className="w-8 h-8 text-green-600 hover:cursor-pointer"
              onClick={shareOnWhatsapp}
            />
            <FaSquareXTwitter
              className="w-8 h-8 hover:cursor-pointer"
              onClick={shareOnTwitter}
            />
            <FaLinkedin
              className="w-8 h-8 text-blue-600 hover:cursor-pointer"
              onClick={shareOnLinkedIn}
            />
            <FaFacebookSquare
              className="w-8 h-8 text-blue-600 hover:cursor-pointer"
              onClick={shareOnFacebook}
            />
          </PopoverContent>
        </Popover>
        <div className="flex space-x-4">
          <p className="text-xl text-primary font-bold">Total score:</p>
          {result?.total_score && (
            <p className="text-xl text-primary lg:text-[1.75rem] font-medium">
              {result?.total_score}%
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Results;
