


/**
 * EasyHTTP Library
 * Library for making HTTP requests
 * @author SuperManic
 * 
 */

const { TOKEN } = require("./axios");

let api_origin = process.env.REACT_APP_BASE_API_URL;

class EasyHTTP {

    async getNoAuth(url) {

        try {
            const res = await fetch(`${api_origin}${url}`, {

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })

            const resData = await res.json()
            return resData
        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }

    async get(url, Token) {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token")?.trim() ?? localStorage.getItem(TOKEN);
        localStorage.setItem(TOKEN, token ?? "")

        try {
            const res = await fetch(`${api_origin}${url}`, {

                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token ?? Token}`,
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })

            const resData = await res.json()
            return resData
        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }

    // Make an HTTP POST Request
    async post(url, data, userToken) {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token")?.trim() ?? localStorage.getItem(TOKEN);
        localStorage.setItem(TOKEN, token ?? "")

        try {
            const res = await fetch(`${api_origin}${url}`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token ?? userToken}`,
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',

                },
                body: JSON.stringify(data) //convert to JSON string
            })
            const postedData = await res.json()
            return postedData

        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }
    // POST WITHOUT AUTH

    async postNoAuth(url, data) {

        try {
            const res = await fetch(`${api_origin}${url}`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',

                },
                body: JSON.stringify(data) //convert to JSON string
            })
            const postedData = await res.json()
            return postedData

        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }
    // Make an HTTP PUT/UPDATE Request
    async put(url, data, userToken) {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token")?.trim() ?? localStorage.getItem(TOKEN);
        localStorage.setItem(TOKEN, token ?? "")

        try {
            const res = await fetch(`${api_origin}${url}`, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token ?? userToken}`,
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'

                },
                body: JSON.stringify(data)
            })
            const editedData = await res.json()
            return editedData
        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }
    // Make an HTTP DELETE Request
    async delete(url) {
        try {
            const res = await fetch(`${api_origin}${url}`, {
                mode: 'cors',
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            })
            const deleteRes = await 'Resource Deleted'
            return deleteRes
        } catch (e) {
            // throw new Error(e.message)
            console.log(e.message)
        }
    }
}


module.exports = EasyHTTP