import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TestScore.css';

import ControlledModal from './ControlledModal';
import Logo from '../../assets/images/logo.png';
import Button from '../Button/Button';
import { useResultQuery } from 'src/lib/endpoints/question/question.api';

const TestScoreModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { result } = useResultQuery()

  const seeResultHandler = () => {
    onClose();
    navigate('/results');
  };

  let contentStyle = {
    maxHeight: '80%',
    borderRadius: '12px',
    backgroundColor: 'white',
    boxShadow: 'none',
    margin: 'auto',
    overflow: 'scroll',
  };

  return (
    <div>
      <ControlledModal
        open={open}
        closeModal={onClose}
        contentStyle={contentStyle}
      >
        <div className='m-auto p-16 text-center'>
          <div className='w-16 mb-5 m-auto'>
            <img src={Logo} alt='droomwork brand logo' />
          </div>
          <p className='text-2xl text-primary font-semibold mb-[84px]'>
            You Scored
          </p>
          {result?.total_score && <p className='text-[2rem] leading-5 m-auto font-semibold text-primary mb-32'>
            {result?.total_score ?? ''}%
          </p>}
          <Button
            className={'bg-primary text-white'}
            onClick={seeResultHandler}
          >
            See full result
          </Button>
        </div>
      </ControlledModal>
    </div>
  );
};

export default TestScoreModal;
