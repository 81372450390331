import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GOOGLE_ANALYTICS, HOTJAR_ID } from "./lib/utils/constants";
import LandingPage from "./pages/Home/LandingPage";
  import Results from "./pages/Results";
  import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "developement") return;
    hotjar.initialize({ id: HOTJAR_ID, sv: 6 });
    ReactGA.initialize(GOOGLE_ANALYTICS);
    ReactGA.send({ hitType: "pageview", page: window?.location?.pathname });
  }, []);

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/results" element={<Results />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjIsImlhdCI6MTcwOTI5NTcyMX0.P5WOG2in6pRNk4X7fptYv4eFrgR4TbBJ-zj9lBMd44o
